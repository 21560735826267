import { useCallback, useEffect, useRef } from "react";
import auth from "cp-client-auth!sofe";
import canopyUrls from "canopy-urls!sofe";
import { captureMessage } from "error-logging!sofe";

import { isEmpty, isEqual, without, cloneDeep, isPlainObject } from "lodash";
import { from, Observable } from "rxjs";
import { skipWhile, first, mergeMap } from "rxjs/operators";

import {
  handleUnknownError,
  handleFetcherResponse,
  CanopyError,
  setHeaders,
} from "./fetcher.helpers.js";
import {
  checkOverrides,
  updateOverride,
  generateMockResponse,
  addOverride,
  removeOverride,
  getOverrides,
  NO_OVERRIDE,
} from "./overrides.helper.js";
import {
  isCached,
  getAffectedCachedItems,
  updateCache,
  forceBustCache,
} from "./caching.js";
import { angularToJson } from "./angular-compat.helpers.js";
import { defaultToAPIUrl } from "./url-prefix.helpers.js";
import { onlineListener } from "online-listener";

let calls: any[] = [];
const MAX_CALLS = 29;
const MAX_TIME = 30000;

export let getCalls = () => calls;
export const onlineObservable = onlineListener;
export * from "src/react-query";

export { addOverride, getOverrides, removeOverride, updateOverride };

export default async function fetcherBase(...args: any[]): Promise<any> {
  if (localStorage.getItem("sofe-inspector")) {
    const override = await checkOverrides(args);

    if (override !== NO_OVERRIDE) {
      console.warn(`A fetcher override is enabled: ${override.url}`);

      if (override.delayOnly) {
        await new Promise((resolve) => setTimeout(resolve, override.delay));
      } else {
        return generateMockResponse(override);
      }
    }
  }

  await onlineObservable
    .pipe(skipWhile((online) => !online))
    .pipe(first())
    .toPromise();

  if (args.length < 1) {
    return Promise.reject(
      new CanopyError("Fetch API must be called with at least one argument")
    );
  }

  if (typeof Request !== "undefined" && args[0] instanceof Request) {
    return Promise.reject(
      new CanopyError(
        "Fetcher does not yet support using a Request object. Try using a string url and config object, instead"
      )
    );
  }

  if (typeof args[0] !== "string") {
    return Promise.reject(
      new CanopyError(
        "Fetcher should be called with a string url as the first argument"
      )
    );
  }

  // @ts-ignore
  // args[0] must be a string since we reject and return early, and so I don't think this first result is ever used
  // I'm just keeping it just in case it breaks something, but we should re-evaluate if this is needed later.
  let url = args[0] instanceof Request ? args[0].url : args[0];
  let retries = 0;

  const duplicateCalls = calls.filter(
    (call) =>
      isEqual(call.args, args) && new Date().getTime() - call.time < MAX_TIME
  );

  if (duplicateCalls.length > MAX_CALLS) {
    let message;
    try {
      message = `Infinite requests detected from the client! ${
        duplicateCalls.length
      } in the last 30 seconds. Call: ${JSON.stringify(args)}`;
    } catch (error) {
      message = `Infinite requests detected from the client! ${duplicateCalls.length} in the last 30 seconds.`;
    }
    return Promise.reject(new CanopyError(message));
  }

  const call = { args: cloneDeep(args), time: new Date().getTime() };

  calls.push(call);

  // Clear history of calls that occurred over 30 seconds ago
  setTimeout(() => {
    calls = without(calls, call);
  }, MAX_TIME);

  // We do not support retrying a fetch call using a Request instance yet
  // @ts-ignore
  // Same issue mentioned above, args[0] is inferred as a string because of the early return, so this case should never run
  if (args[0] instanceof Request) {
    console.warn(
      "fetcher calls using a Request instance are not supported. A regular fetch call has been returned."
    );
    // @ts-ignore
    return fetch.apply(null, args);
  }

  if (!args[1]) {
    args.push({});
  }

  let passThrough401 = args[1].passThrough401;
  delete args[1].passThrough401;

  if (args[2]) {
    // We use a third argument for recursive fetch calls (retrying on re-auth)
    // But the fech API does not itself have 3 arguments and shouldn't be used by
    // consumers of fetcher.
    retries = args.pop();
  }

  const alreadyRefreshedToken =
    args.length >= 4 && typeof args[3] === "object"
      ? args[3].alreadyRefreshedToken
      : false;

  // Sets the headers correctly on the call
  if (!args[1].headers) {
    args[1].headers = new Headers();
    if (args[1].body && isPlainObject(args[1].body)) {
      args[1].headers.append("Content-Type", "application/json");
    }
  } else if (!(args[1] instanceof Headers)) {
    args[1].headers = new Headers(args[1].headers);
  }

  args[0] = defaultToAPIUrl(args[0]);

  setHeaders((key: string, value: string) => args[1].headers.set(key, value));

  const params = new URL(window.location.toString()).searchParams;
  const preAuthSessionId = params.get("session_id");
  const omitCredentials = args[1]?.credentials === "omit";
  if (preAuthSessionId && !omitCredentials) {
    args[1].credentials = "omit";
    args[1].headers.append("Limited-Access", preAuthSessionId);
  } else if (!args[1].credentials) {
    args[1].credentials = "include";
  }

  if (isPlainObject(args[1].body)) {
    try {
      args[1].body = JSON.stringify(args[1].body);
    } catch (err) {
      /* Don't do anything here. Maybe the user of fetcher knows what they are doing by providing an object.
       * It is unlikely that it will actually work, but we can just let native fetch be the one to throw the
       * Error.
       */
    }
  }

  if (args[1].body) {
    args[1].body = angularToJson(args[1].body, false);
  }

  return fetch
    .apply(null, args as any)
    .then((response) => {
      if (response.status === 401 && !passThrough401) {
        if (preAuthSessionId) {
          // Pre-auth session cannot be refreshed to try again so
          // we have to assume the session is no longer valid.
          setTimeout(() => {
            // @ts-ignore
            // Leaving this for now just to ensure no breakage, but window.location is meant to read only and we should use window.location.href or window.location.assign
            window.location = `${canopyUrls.getAuthUrl()}/l/unauthorized`;
          });
          // If we resolve, the browser has a chance to error and throw toasts.
          return new Promise(() => {});
        }

        if (retries > 1) {
          captureMessage(
            `WARNING: Infinite loop detected when requesting: ${url}`
          );

          setTimeout(() => {
            // @ts-ignore
            // Leaving this for now just to ensure no breakage, but window.location is meant to read only and we should use window.location.href or window.location.assign
            window.location = `${canopyUrls.getAuthUrl()}/logout?redirect_url=${encodeURIComponent(
              window.location.origin
            )}`;
          });

          // We want to return a promise that never resolves because above we are redirecting to login.
          // If we resolve, the browser has a chance to error and throw toasts.
          return new Promise(() => {});
        }

        if (alreadyRefreshedToken) {
          // The backend is continuing to return 401 even though we just refreshed the auth token????
          // That is a backend bug, but to prevent thrashing / inf loop, we just bail and throw an error
          // instead of refreshing the token yet again.
          const rejection = new CanopyError(
            `Backend endpoints disagree about whether user is logged in -- token was refreshed but endpoints still return 401. endpoint '${url}'`
          );
          rejection.status = 401;
          return fetch("/wg/token?logInfo=true", {
            method: "get",
            headers: {
              Accept: "application/json",
            },
            credentials: "include",
          })
            .then(() => Promise.reject(rejection))
            .catch(() => Promise.reject(rejection));
        } else {
          return auth
            .refreshAuthToken({ clientSecret: "TaxUI:f7fsf29adsy9fg" })
            .then(
              fetcherBase.bind(null, ...args, retries + 1, {
                alreadyRefreshedToken: true,
              })
            );
        }
      }

      return Promise.resolve(response).then((resp) => {
        // If this is a GET, PUT, or PATCH on a url that's in the cache, we need to update the cached Subject to reflect the new data.
        // The API's PUT/PATCH response must be the complete object.
        if (
          isCached(url) &&
          (!args[1].method ||
            args[1].method.toLowerCase() === "put" ||
            args[1].method.toLowerCase() === "patch" ||
            args[1].method.toLowerCase() === "get")
        ) {
          const oldRespJson = resp.json;

          resp.json = function () {
            return new Promise((resolve, reject) => {
              oldRespJson
                .call(resp)
                .then((json) => {
                  updateCache(url, json);
                  resolve(json);
                })
                .catch(reject);
            });
          };
        }

        // The PUT/PATCH may also affect cached items that share the same base URL (but with a different query string). Those should be invalidated/updated.
        let affectedItems = getAffectedCachedItems(url);
        if (
          !isEmpty(affectedItems) &&
          args.length >= 2 &&
          args[1].method &&
          (args[1].method.toLowerCase() === "put" ||
            args[1].method.toLowerCase() === "patch")
        ) {
          for (let key in affectedItems) {
            // Re-get them using the forceBust option to update the cache
            forceBustCache(key);
          }
        }

        return resp;
      });
    })
    .catch(handleUnknownError);
}

export { useObservable } from "./custom-hooks/use-observable.js";
export { useFetcher } from "./custom-hooks/use-fetcher.js";
export {
  getWithSharedCache,
  fetchWithSharedCache,
  forceBustCache,
  bustCacheForSubscriptionDuration,
} from "./caching.js";
export { fetchWithProgress } from "./call-api-with-progress.js";
export { onPusher } from "./push.js";
export { redirectOrCatch } from "./redirect-or-catch.js";

export function fetchAsObservable(...args: any[]) {
  if (args.length === 0) {
    throw Error(`Cannot call fetchAsObservable without arguments`);
  }

  const originalError = new Error();

  const responseType = (args[1] && args[1].responseType) || "default";

  return Observable.create((observer: any) => {
    // We want the actual network request to be canceled when the observable subscription is disposed of, in the browsers that support it
    const abortController = new AbortController();

    // To be handled in the flat map, not by the caller of fetchAsObservable
    observer.next(fetchWithAbortController(args, abortController));

    return function whenDisposed() {
      // Abort the network request when the subscription is disposed of
      abortController.abort();
    };
  }).pipe(
    mergeMap((fetcherPromise) =>
      from(handleFetcherResponse(fetcherPromise, responseType, originalError))
    ),
    first()
  );
}

export function fetcher(...args: any[]) {
  if (args.length === 0) {
    return Promise.reject(new Error(`Cannot call fetcher without arguments`));
  }

  return new Promise((resolve, reject) => {
    const responseType = args[1] && args[1].responseType;
    const fetcherPromise =
      args[1] && args[1].signal
        ? fetchWithAbortController(args, { signal: args[1].signal })
        : fetcherBase(...args);

    handleFetcherResponse(fetcherPromise, responseType, new Error())
      .then((response: any) => resolve(response))
      .catch((error: any) => reject(error));
  });
}

function fetchWithAbortController(args: any[], abortController: any) {
  const fetchOptions = args.length >= 2 ? args[1] : {}; // Request object as argument isn't something this code supports
  fetchOptions.signal = abortController.signal; // So we can cancel the request
  const fetchArgs = [args[0], fetchOptions];

  return fetcherBase(...fetchArgs);
}

/** fetch abort
 * @returns {function: Promise<any>}
 */
export function fetchAbort() {
  const abortController = new AbortController();
  const runFetch = (...args: any) => {
    const originalError = new Error();
    const responseType = (args[1] && args[1].responseType) || "default";
    return handleFetcherResponse(
      fetchWithAbortController(args, abortController),
      responseType,
      originalError
    );
  };
  runFetch.abort = () => abortController.abort();
  return runFetch;
}

/** use fetch abort
 * @param opts {{ abortOnUnmount: boolean }}
 * @returns {[() => Promise<any>, () => void]}
 */
export function useFetchAbort(opts = { abortOnUnmount: true }) {
  const abortControllerRef = useRef(new AbortController());
  const runFetch = useCallback((...args) => {
    const originalError = new Error();
    const responseType = (args[1] && args[1].responseType) || "default";
    return handleFetcherResponse(
      fetchWithAbortController(args, abortControllerRef.current),
      responseType,
      originalError
    );
  }, []);
  const runAbort = useCallback(() => {
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();
  }, []);
  useEffect(() => {
    return () => {
      if (opts.abortOnUnmount) {
        runAbort();
      }
    };
  }, [opts.abortOnUnmount]);
  return [runFetch, runAbort];
}
